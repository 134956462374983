import { Box, Link } from "@chakra-ui/core";
import React from "react";
import AppDownloadButtons from "./AppDownloadButtons";
import ARFrog from "./ARFrog";
import BodyText from "./BodyText";
import Container from "./Container";
import ContentfulRichText from "./ContentfulRichText";
import DataVizMap from "./DataVizMap";
import FrogFactSheets from "./FrogFactSheets";
import FrogIDProjectHighlights from "./FrogIDProjectHighlights";
import FrogIDOneMillionthFrog from "./FrogIDOneMillionthFrog";
import FrogIDProjectTopFroggers from "./FrogIDProjectTopFroggers";
import FrogIDProjectTopGroups from "./FrogIDProjectTopGroups";
import FrogIDWeekHighlights from "./FrogIDWeekHighlights";
import FrogIDWeekHighlights2020 from "./FrogIDWeekHighlights2020";
import FrogIDWeekHighlights2021 from "./FrogIDWeekHighlights2021";
import FrogIDWeekHighlights2022 from "./FrogIDWeekHighlights2022";
import FrogIDWeekHighlights2023 from "./FrogIDWeekHighlights2023";
import FrogIDWeekHighlights2024 from "./FrogIDWeekHighlights2024";
import FrogIDWeekTopFroggers from "./FrogIDWeekTopFroggers";
import FrogIDWeekTopFroggers2020 from "./FrogIDWeekTopFroggers2020";
import FrogIDWeekTopFroggers2021 from "./FrogIDWeekTopFroggers2021";
import FrogIDWeekTopFroggers2022 from "./FrogIDWeekTopFroggers2022";
import FrogIDWeekTopFroggers2023 from "./FrogIDWeekTopFroggers2023";
import FrogIDWeekTopFroggers2024 from "./FrogIDWeekTopFroggers2024";
import FrogIDWeekTopGroups from "./FrogIDWeekTopGroups";
import SchoolsLeaderboard from "./SchoolsLeaderboard";
import SocialMediaButtons from "./SocialMediaButtons";
import SpatialCoverageMap from "./SpatialCoverageMap";

interface CustomComponentProps {
  type: string;
  description: any;
}

interface WrapperProps extends CustomComponentProps {
  paddingTop: number;
  paddingBottom: number;
  backgroundColor: string;
}

const CustomComponentWrapper: React.FC<WrapperProps> = ({
  paddingTop,
  paddingBottom,
  backgroundColor,
  ...props
}) => {
  const pt = paddingTop != null ? paddingTop : 24;
  const pb = paddingBottom != null ? paddingBottom : 24;
  const bg = backgroundColor != null ? backgroundColor : "transparent";
  return (
    <Box pt={[pt / 2, pt / 2, pt, pt]} pb={[pb / 2, pb / 2, pb, pb]} bg={bg}>
      <CustomComponent {...props} />
    </Box>
  );
};

const CustomComponent: React.FC<CustomComponentProps> = ({
  type,
  description
}) => {
  switch (type) {
    case "App Download Buttons":
      return <AppDownloadButtons />;
    case "FrogID Week Highlights":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekHighlights />
        </Container>
      );
    case "FrogID Week Highlights 2020":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekHighlights2020 />
        </Container>
      );
    case "FrogID Week Highlights 2021":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekHighlights2021 />
        </Container>
      );
      case "FrogID Week Highlights 2022":
        return (
          <Container textAlign="center">
            <ContentfulRichText content={description} />
            <FrogIDWeekHighlights2022 />
          </Container>
        );
      case "FrogID Week Highlights 2023":
        return (
          <Container textAlign="center">
            <ContentfulRichText content={description} />
            <FrogIDWeekHighlights2023 />
          </Container>
        );
      case "FrogID Week Highlights 2024":
        return (
          <Container textAlign="center">
            <ContentfulRichText content={description} />
            <FrogIDWeekHighlights2024 />
          </Container>
        );
    case "FrogID Week Top Groups":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopGroups />
        </Container>
      );
    case "FrogID Week Top Froggers":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopFroggers />
        </Container>
      );
    case "FrogID Week Top Froggers 2020":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopFroggers2020 />
        </Container>
        );
    case "FrogID Week Top Froggers 2021":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopFroggers2021 />
        </Container>
        );
    case "FrogID Week Top Froggers 2022":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopFroggers2022 />
        </Container>
        );
    case "FrogID Week Top Froggers 2023":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopFroggers2023 />
        </Container>
        )
    case "FrogID Week Top Froggers 2024":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDWeekTopFroggers2024 />
        </Container>
        )
    case "FrogID Project Highlights":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDProjectHighlights />
          <BodyText>
            <Link
              color="primary.orange"
              href="/signup"
            >
              Create a profile
            </Link>{" "}
            to contribute to FrogID.
          </BodyText>
        </Container>
      );
    case "FrogID One Millionth Frog":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDOneMillionthFrog />
        </Container>
      );
    case "Spatial Coverage Map":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <SpatialCoverageMap iframeUrl="https://austmus.github.io/frog-spatial-coverage/" />
        </Container>
      );
    case "Spatial Coverage Map NW":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <SpatialCoverageMap iframeUrl="https://austmus.github.io/frog-spatial-coverage-nw/" />
        </Container>
      );
    case "Spatial Coverage Table NW":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <SpatialCoverageMap iframeUrl="https://austmus.github.io/frog-spatial-coverage-nw-table/" />
        </Container>
      );
    case "FrogID Project Top Groups":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDProjectTopGroups />
        </Container>
      );
    case "FrogID Project Top Froggers":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <FrogIDProjectTopFroggers />
        </Container>
      );
    case "Schools Leaderboard":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <SchoolsLeaderboard />
          <BodyText>
            <Link
              fontWeight="semibold"
              color="primary.orange"
              href="/signup"
            >
              Create a Class Group
            </Link>{" "}
            to contribute to FrogID.
          </BodyText>
        </Container>
      );
    case "Frog Fact Sheets":
      return (
        <Container>
          <FrogFactSheets />
        </Container>
      );
    case "Social Media Buttons":
      return (
        <Container textAlign="center">
          <ContentfulRichText content={description} />
          <SocialMediaButtons />
        </Container>
      );
    case "DataViz Map":
      return (
        <Container textAlign="center">
          <DataVizMap />
        </Container>
      );
    case "AR Frog":
      return (
        <Container textAlign="center">
          <ARFrog />
        </Container>
      )
    default:
      return null;
  }
};

export default CustomComponentWrapper;
