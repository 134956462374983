import { Flex } from "@chakra-ui/core";
import React from "react";

interface Props {}

interface Props {
  iframeUrl: string;
}

// This component is designed to display the frog spatial coverage map
//  which is hosted on its own website. 
const SpatialCoverageMap: React.FC<Props> = ({ iframeUrl }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={["column", "column", "row", "row"]}
      flexWrap="wrap"
      my={12}
    >
      <iframe src={iframeUrl} title="Embedded Content" width="100%" height="700" frameBorder="0" />
    </Flex>
  );
};

export default SpatialCoverageMap;
